import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";

const CodepenProjectSection = styled(ProjectSection)`
  background: #2d979b;
`;

const CodepenIframe = styled.iframe`
  width: 100%;
  display: block;
  height: 45.2rem;
  padding: 5.106382978723%;
  padding-bottom: 0px;
  min-height: 14.41667rem;
  background: #000000;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  margin-bottom: 0;
  border: none;
`;

function CodepenWebType() {
  return (
    <CodepenProjectSection>
      <ProjectTitle
        title="Web Type"
        tag="Codepen"
        tagLink="https://codepen.io/trilm/pen/vOEJEB"
      />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="CSS">
          <Icons type="css-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        Ever since learning how to create online experiences with code, I have
        always been interested in how to apply typography to the web. This
        Codepen example is one of my explorations with responsive type on the
        web.
      </ProjectDescription>
      <CodepenIframe
        height="452"
        scrolling="no"
        title="Typography on the web"
        src="https://codepen.io/trilm/embed/vOEJEB?height=265&theme-id=dark&default-tab=result"
        frameborder="no"
        allowtransparency="true"
        allowfullscreen="true"
      >
        See the Pen{" "}
        <a href="https://codepen.io/trilm/pen/vOEJEB">Typography on the web</a>{" "}
        by Michael Trilford (<a href="https://codepen.io/trilm">@trilm</a>) on{" "}
        <a href="https://codepen.io">CodePen</a>.
      </CodepenIframe>
    </CodepenProjectSection>
  );
}

export default CodepenWebType;
