import React, { useState } from "react";
import styled, { css } from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import Served1 from "../../../../img/served-1.jpg";
import Served2 from "../../../../img/served2.gif";
import ServedVideo from "../../../../video/served.mp4";
import IPhoneImage from "../../../../img/iphone-device.png";
import ServedPoster from "../../../../img/served2.gif";

const ServedProjectSection = styled(ProjectSection)`
  background: #0096ad;
`;

const ServedProjectDescription = styled(ProjectDescription)`
  @media (pointer: fine) {
    margin-bottom: var(--spacing-clear);
  }
`;

const IPhone = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  pointer-events: none;
  z-index: 1;
`;

const IPhoneWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 30rem;
  height: 65rem;
  margin: 0;
  align-items: center;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  padding: 2.1rem;
  &:focus {
    outline: none;
  }
`;

const ModalButton = styled.button`
  display: none;
  @media (pointer: fine) {
    display: block;
    margin: var(--spacing-medium) auto var(--spacing-x-medium);
    background-color: rgba(0, 0, 0, 0.05);
    display: inline-block;
    text-align: center;
    color: white;
    height: auto;
    text-decoration: none;
    padding: 0px 1.5em;
    border-radius: 3.125em;
    line-height: 3;
    box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
      0 -1px 0 0 rgba(255, 255, 255, 0.1);
    border-bottom: none;
    font-size: var(--font-size-text-small);
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

const Modal = styled.div`
  ${(props) =>
    props.modalOpen &&
    css`
      background: #000000cc;
      top: 0;
      left: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 100000;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        display: block;
        margin: var(--spacing-medium) auto var(--spacing-clear);
        background-color: #333333;
        display: inline-block;
        text-align: center;
        color: white;
        height: auto;
        text-decoration: none;
        padding: 0px 1.5em;
        border-radius: 3.125em;
        line-height: 3;
        box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
          0 -1px 0 0 rgba(255, 255, 255, 0.1);
        border-bottom: none;
        font-size: var(--font-size-text-small);
        position: absolute;
        left: 24px;
        top: 24px;
        &:hover {
          color: #ff73df;
        }
      }
    `}
`;

function Served() {
  const [modalOpen, setOpen] = useState(true);
  return (
    <ServedProjectSection>
      <ProjectTitle title="Served" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <ServedProjectDescription>
        Served is a mobile app that provides a new way of ordering and paying at
        restaurants. I explored the end-to-end experience of how a user would
        browse the restaurant menu, right through to settling the bill. With the
        inconvenience of Covid-19 for restaurants to operate as usual, I have
        recently explored how to enable this service to allow restaurants to
        have complete control of home deliveries.
      </ServedProjectDescription>
      <ModalButton onClick={() => setOpen(!modalOpen)}>Watch video</ModalButton>
      {modalOpen ? (
        ""
      ) : (
        <Modal modalOpen>
          <button onClick={() => setOpen(!modalOpen)}>Close</button>
          <IPhoneWrapper>
            <IPhone src={IPhoneImage} />
            <Video width="300" height="650" controls poster={ServedPoster}>
              <source src={ServedVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </IPhoneWrapper>
        </Modal>
      )}
      <ProjectHeroImage srcMobile1={Served1} srcMobile2={Served2} mobile />
    </ServedProjectSection>
  );
}

export default Served;
