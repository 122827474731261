import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import Image from "../../../../img/outcome-roadmap.png";

const MuiProjectSection = styled(ProjectSection)`
  background: #2e2e2e;
`;

function Outcomes() {
  return (
    <MuiProjectSection>
      <ProjectTitle title="Outcomes" tag="Coming Soon" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="CSS">
          <Icons type="css-small" />
        </Badge>
        <Badge tooltip="large" small title="React">
          <Icons type="react-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        Outcomes is an app that helps users track objectives and key results in
        an outcome-based roadmap format. It organises tasks into{" "}
        <strong style={{ color: "#a4fc67" }}>Upcoming</strong>,{" "}
        <strong style={{ color: "#a4fc67" }}>Doing</strong>, and{" "}
        <strong style={{ color: "#a4fc67" }}>Finished</strong> columns and
        includes tracking for key results for each objective.
      </ProjectDescription>

      <ProjectHeroImage src={Image} desktop />
    </MuiProjectSection>
  );
}

export default Outcomes;
