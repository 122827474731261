import React from "react";
import styled from "styled-components";
import CodepenToggleColor from "./IndividualProjects/CodepenToggleColor";
// import CodepenCSSGrid from "./IndividualProjects/CodepenCSSGrid";
import CodepenWebType from "./IndividualProjects/CodepenWebType";
import CodepenGoogleGlass from "./IndividualProjects/CodepenGoogleGlass";
// import CodepenMobileFirst from "./IndividualProjects/CodepenMobileFirst";
// import CodepenResponsiveType from "./IndividualProjects/CodepenResponsiveType";
// import DebtorDaddy from "./IndividualProjects/DebtorDaddy";
// import WW100 from "./IndividualProjects/WW100";
import Sights from "./IndividualProjects/Sights";
import Royalty from "./IndividualProjects/Royalty";
// import Formalize from "./IndividualProjects/Formalize";
import TimeCapsule from "./IndividualProjects/TimeCapsule";
import Nga from "./IndividualProjects/Nga";
import Mui from "./IndividualProjects/Mui";
import OnTheWrist from "./IndividualProjects/OnTheWrist";
import Capture from "./IndividualProjects/Capture";
// import OutcomeBasedRoadmap from "./IndividualProjects/OutcomeBasedRoadmap";
import CaseStudies from "./IndividualProjects/CaseStudies";
import BladeRunner2049 from "./IndividualProjects/Bladerunner2049";
// import OnlineInvoicing from "./IndividualProjects/OnlineInvoicing";
import MobileBanking from "./IndividualProjects/MobileBanking";
import Served from "./IndividualProjects/Served";
import MYOBInvoices from "./IndividualProjects/MYOBInvoices";
import IOS14 from "./IndividualProjects/IOS14";
import Sensei from "./IndividualProjects/Sensei";
import Outcomes from "./IndividualProjects/outcomes";
import Feelix from "./IndividualProjects/Feelix";
// import Invoicing from "./IndividualProjects/Invoicing";

const ProjectWrapper = styled.article`
  margin-bottom: 0;
`;

const Main = () => {
  return (
    <ProjectWrapper>
      <Sensei />
      <Outcomes />
      <MYOBInvoices />
      <Feelix />
      {/* <Invoicing /> */}
      <CaseStudies />
      <Served />
      <Capture />
      <MobileBanking />
      <OnTheWrist />
      <CodepenWebType />
      <IOS14 />
      <Royalty />
      <CodepenToggleColor />
      <CodepenGoogleGlass />
      <Mui />
      <Nga />
      <BladeRunner2049 />
      <TimeCapsule />
      <Sights />
    </ProjectWrapper>
  );
};

export default Main;
