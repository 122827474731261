import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import Image from "../../../../img/feelix.png";

const MuiProjectSection = styled(ProjectSection)`
  background: #2f3d50;
`;

function Feelix() {
  return (
    <MuiProjectSection>
      <ProjectTitle title="Feelix – Design System" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="CSS">
          <Icons type="css-small" />
        </Badge>
        <Badge tooltip="large" small title="React">
          <Icons type="react-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        Contributed to the evolution of Feelix by shaping its documentation
        site, collaborating with engineers to develop key components for the
        MYOB product experience, crafting design tokens, and enhancing
        responsive design support through CSS contributions.
      </ProjectDescription>

      <ProjectHeroImage src={Image} desktop />
    </MuiProjectSection>
  );
}

export default Feelix;
